import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import colors from "@constants/colors"
import animation, { cubicBezier } from "@constants/animation"
import breakpoints from "@constants/breakpoints"
import {
  smallcapsBold13Styles,
  smallcapsRegular13Styles,
  body16MainStyles,
  body14Styles,
  subtextStyles,
} from "@constants/typography"

export interface FooterLinkProps {
  link?: string
  text: string
  external?: boolean
  openNewTab?: boolean
}

export interface FooterProps {
  user?: string
  footerShopLabelLink?: FooterLinkProps
  footerShopLinks?: Array<FooterLinkProps>
  footerExploreLabelLink?: FooterLinkProps
  footerExploreLinks?: Array<FooterLinkProps>
  footerExploreLinksMobile?: Array<FooterLinkProps>
  footerBusinessLabelLink?: FooterLinkProps
  footerBusinessLinks?: Array<FooterLinkProps>
  footerSupportLabelLink?: FooterLinkProps
  footerSupportLinks?: Array<FooterLinkProps>
  socialLinks?: {
    facebook?: string
    instagram?: string
    twitter?: string
    youtube?: string
  }
  legalLinks: {
    limitationsOfLiability?: string
    privacyPolicy?: string
    termsAndConditions?: string
  }
  copyrightText?: string
}

export const Footer = styled.footer`
  height: 100%;
  width: 100%;
  margin-top: auto;
  padding: 72px 32px 49px;
  background-color: ${colors.black};

  @media ${breakpoints.tablet} {
    display: flex;
    height: auto;
    padding: 124px calc(1 / 12 * 100vw) 74px;
    flex-direction: column;
  }

  @media ${breakpoints.laptop} {
    display: flex;
    padding: 93px calc(1 / 12 * 100vw) 95px;
    flex-direction: column;
  }
`

export const LogoWrapper = styled.div`
  ${smallcapsBold13Styles}
  display: flex;
  height: 54px;
  margin-bottom: 16px;
  align-items: center;
  color: ${colors.offWhite};

  @media ${breakpoints.tablet} {
    width: 100%;
    margin-bottom: 6px;
  }
`

export const Logo = styled(Link)`
  display: block;
  width: 59px;
  height: 18px;
  cursor: pointer;
`

export const Icon = styled.div`
  width: 24px;
  height: 24px;
`

export const Header = styled.div`
  display: flex;
  margin-bottom: 45px;
  align-items: center;
  justify-content: space-between;
`

export const User = styled(Link)`
  ${body16MainStyles}
  display: flex;
  align-items: center;
  color: ${colors.offWhite};
  transform: translateX(4px);
  cursor: pointer;

  @media ${breakpoints.tablet} {
    display: flex;
    height: 48px;
    padding: 0 24px;
    margin-top: 76px;
    transform: translateX(-24px);
    border-radius: 2px;
  }

  @media ${breakpoints.laptop} {
    display: flex;
    height: 64px;
    margin-right: auto;
    padding: 0 24px;
    transform: translateX(-24px);
    border-radius: 2px;

    &:hover {
      background-color: ${colors.mediumGray10Percent};
    }
  }
`

export const UserIcon = styled(Icon)`
  margin-left: 11px;

  @media ${breakpoints.laptop} {
    margin-left: 0;
    margin-right: 11px;
  }
`

export const Main = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;

  @media ${breakpoints.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media ${breakpoints.laptop} {
    margin-bottom: auto;
    flex-direction: row;
  }
`

export const Locale = styled.div`
  ${smallcapsBold13Styles}
  display: flex;
  position: absolute;
  top: 15px;
  right: 0;
  align-items: center;
  color: ${colors.mediumDarkGray};
  line-height: 24px;

  @media ${breakpoints.tablet} {
    position: relative;
    top: initial;
    right: initial;
    padding: 10px 0;
  }

  @media ${breakpoints.laptop} {
    position: relative;
    top: initial;
    right: initial;
    padding: 10px 0;
  }
`

export const LocaleIcon = styled(Icon)`
  transform: rotate(90deg);
`

const footerLinkProps = css`
  ${body16MainStyles}
  margin: 17px 0;
  color: ${colors.offWhite};
  cursor: pointer;

  @media ${breakpoints.tablet} {
    position: relative;
    margin: 0;
    padding: 14px 0;
    opacity: 0.9;
  }

  @media ${breakpoints.laptop} {
    position: relative;
    margin: 0;
    padding: 14px 0;
    opacity: 0.9;

    &:hover {
      &::before {
        width: 1px;
        background-color: ${colors.dashGray};
        transform: scaleX(8);
        transition: background-color 0 linear 50ms,
          transform ${animation.medium} ${cubicBezier};
      }

      span {
        transform: translateX(24px);
        transition: transform ${animation.medium} ${cubicBezier};
      }
    }

    &::before {
      position: absolute;
      top: 24px;
      left: 1px;
      width: 0px;
      height: 1px;
      background-color: ${colors.black};
      content: "";
      transition: background-color ${animation.medium} ${cubicBezier} 50ms,
        transform ${animation.medium} ${cubicBezier} 50ms;
      transform-origin: left;
    }

    span {
      display: flex;
      transition: transform ${animation.medium} ${cubicBezier};
    }
  }
`

export const ExternalFooterLinkComponent = styled.a`
  ${footerLinkProps}
`

export const FooterLinkComponent = styled(Link)`
  ${footerLinkProps}
`

export const FooterLink: React.FC<{
  link?: string
  text?: string
  external?: boolean
  openNewTab?: boolean
}> = ({ link, text, external, openNewTab }) => {
  return external ? (
    <ExternalFooterLinkComponent
      href={link}
      target={openNewTab ? "_blank" : undefined}
      rel="noopener"
    >
      <span>{text}</span>
    </ExternalFooterLinkComponent>
  ) : (
    <FooterLinkComponent to={link || ""}>
      <span>{text}</span>
    </FooterLinkComponent>
  )
}

export const LinkHeader = styled.div`
  ${smallcapsBold13Styles}
  margin: 19px 0;
  color: ${colors.mediumDarkGray};
`

export const Social = styled.div`
  display: flex;
  margin: 32px 0 48px;
  transform: translateX(-12px);

  @media ${breakpoints.tablet} {
    margin: 76px 0 56px;
    transform: translateX(-12px);
  }

  @media ${breakpoints.laptop} {
    height: 100%;
    margin: 0;
    margin-right: -20px;
    align-items: center;
    justify-content: space-between;
    transform: translateX(-14px);
  }
`

export const SocialIconWrapper = styled.a`
  display: flex;
  width: 48px;
  height: 48px;
  margin-right: 24px;
  align-items: center;
  justify-content: center;

  @media ${breakpoints.tablet} {
    margin-right: 46px;
  }

  @media ${breakpoints.laptop} {
    width: 64px;
    height: 64px;
    margin-right: 0;
    transition: background-color ${animation.notQuiteLong} ${cubicBezier};
    border-radius: 2px;

    &:hover {
      background-color: ${colors.mediumGray10Percent};
    }
  }
`

export const SocialIcon = styled(Icon)`
  cursor: pointer;
  line-height: initial;
`

export const Legal = styled.div`
  margin-bottom: 18px;
`

export const LegalGroup = styled.div`
  margin-bottom: 2px;

  @media ${breakpoints.tablet} {
    display: flex;
    margin-bottom: 32px;
  }
`

export const LegalLink = styled(Link)`
  ${subtextStyles}
  text-decoration: underline;
  text-align: right;
  color: ${colors.offWhite};
  opacity: 0.5;
  cursor: pointer;
  transition: color ${animation.quick} ${cubicBezier},
    opacity ${animation.quick} ${cubicBezier};

  @media ${breakpoints.tablet} {
    ${body14Styles}
    color: ${colors.copyrightGray};
    text-decoration: none;
    opacity: 1;
    margin-right: 24px;
  }

  @media ${breakpoints.laptop} {
    ${body14Styles}
    display: flex;
    height: 100%;
    align-items: center;
    color: ${colors.copyrightGray};
    text-decoration: none;
    opacity: 1;

    &:hover {
      color: ${colors.copyrightGrayHover};
      opacity: 1;
    }
  }
`

export const Copyright = styled.div`
  ${smallcapsRegular13Styles}
  font-size: 9px;
  color: ${colors.mediumDarkGray};
  line-height: 9px;

  @media ${breakpoints.tablet} {
    font-size: 11px;
    color: ${colors.copyrightGray};
    line-height: 16px;
  }

  @media ${breakpoints.laptop} {
    font-size: 11px;
    color: ${colors.copyrightGray};
    line-height: 16px;
  }
`
