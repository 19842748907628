import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import colors from "@constants/colors"
import animation, { cubicBezierBackgroundColor } from "@constants/animation"
import breakpoints from "@constants/breakpoints"
import {
  smallcapsBold14Styles,
  smallcapsBold13Styles,
} from "@constants/typography"
import AnimatedDash, { animationStyles } from "../common/AnimatedDash"

const GatsbyLink = styled(Link)``

const ExternalLink = styled.a``

const whiteProps = `
  color: ${colors.black};
  background-color: ${colors.white};
  border: solid 1px ${colors.black};

  &:active {
    background-color: ${colors.white};
  }

  @media ${breakpoints.laptop} {
    color: ${colors.black};

    &:hover {
      background-color: ${colors.white};
    }
  }
`
export const Button = styled.button<any>`
  ${smallcapsBold13Styles}
  display: flex;
  position: relative;
  height: 48px;
  width: 100%;
  padding: 0 24px;
  align-items: center;
  justify-content: space-between;
  color: ${colors.offWhite};
  line-height: 22px;
  background-color: ${colors.black};
  border-radius: 2px;
  border: none;
  transition: background-color ${animation.medium} ${cubicBezierBackgroundColor};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: ${colors.black};
    transition: none;
  }

  ${animationStyles}

  @media ${breakpoints.laptop} {
    ${smallcapsBold14Styles}
    color: ${colors.offWhite};
    line-height: 22px;

    &:hover {
      background-color: ${colors.hoverBlack};
    }
  }

  ${({ buttonStyles }) => (buttonStyles ? buttonStyles : "")}
  ${({ white }) => (white ? whiteProps : "")}
`

export const TextWrapper = styled.span`
  padding: 4px 24px 0 0;
`

export interface LinkButtonProps {
  to: string
  text: string
  buttonStyles?: string
  className?: string
  external?: boolean
  target?: string
  white?: boolean
  noDash?: boolean
}

const LinkButton: React.FC<LinkButtonProps> = ({
  to,
  text,
  className,
  buttonStyles,
  external,
  target,
  white,
  noDash,
}) => {
  const children = (
    <Button buttonStyles={buttonStyles} white={white}>
      <TextWrapper>{text}</TextWrapper>
      {!noDash && <AnimatedDash color={white ? colors.black : colors.white} />}
    </Button>
  )
  return external ? (
    <ExternalLink
      href={to}
      target={target}
      className={className}
      rel="noopener"
    >
      {children}
    </ExternalLink>
  ) : (
    <GatsbyLink to={to || ""} target={target} className={className}>
      {children}
    </GatsbyLink>
  )
}

export default LinkButton
