import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Image, { FluidObject, GatsbyImageProps } from "gatsby-image"
import breakpoints from "@constants/breakpoints"
import colors from "@constants/colors"
import {
  h4Styles,
  h5Styles,
  body16MainStyles,
  body14Styles,
  smallcapsBold13Styles,
} from "@constants/typography"
import LinkButton, { Button } from "@components/buttons/LinkButton"
import { MobileAndDesktopOnly, TabletOnly } from "@utils/MediaQuery"

const GatsbyLink = styled(Link)<any>`
  position: relative;
  ${({ to }) => (!to.length ? "pointer-events: none" : "cursor: pointer")};
`

const GatsbyImage = styled(Image)<GatsbyImageProps>`
  position: relative;
  width: 100%;
  height: calc(100vw * 1.13);
  flex-grow: 0;
  flex-shrink: 0;

  @media ${breakpoints.tablet} {
    width: 100vw;
    height: calc(100vw * 0.885);
    align-self: stretch;
  }

  @media ${breakpoints.laptop} {
    width: calc(7 / 12 * 100vw);
    height: calc(7 / 12 * 100vw * 0.55);
    align-self: stretch;
  }
`

const ContentContainer = styled.div`
  display: flex;
  padding: 33px 20px 74px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;

  @media ${breakpoints.tablet} {
    padding: calc(1 / 12 * 100vw) calc(1 / 12 * 100vw) calc(1.75 / 12 * 100vw);
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media ${breakpoints.laptop} {
    flex: 1;
    padding: 24px 0 24px calc(0.5 / 12 * 100vw);
  }

  @media ${breakpoints.desktop} {
    padding: 24px 0 80px calc(0.5 / 12 * 100vw);
  }
`

const HighlightSpacer = styled.div`
  height: 30px;
`

const HighlightLabel = styled.div`
  ${smallcapsBold13Styles}
  display: none;
  margin-bottom: 21px;
  color: ${colors.brandOrange};

  @media ${breakpoints.desktop} {
    display: initial;
  }
`

const HighlightText = styled.div`
  ${smallcapsBold13Styles}
  display: flex;
  height: 24px;
  padding: 3px 8px 0;
  align-items: center;
  color: ${colors.offWhite};
  background-color: ${colors.brandOrange};
  border-radius: 2px;

  @media ${breakpoints.tablet} {
    position: absolute;
    left: 0;
    bottom: 26px;
    padding: 3px 8px 0 24px
    background-color: ${colors.black};
  }
`

const Title = styled.h4`
  ${h4Styles}
  margin: 22px 0 15px;

  @media ${breakpoints.tablet} {
    width: calc(5 / 12 * 100vw);
    margin: 0 0 calc(0.5 / 12 * 100vw);
    padding-right: calc(2 / 12 * 100vw);
  }

  @media ${breakpoints.laptop} {
    ${h4Styles}
    margin: auto 0 16px;
  }

  @media ${breakpoints.desktop} {
    margin: auto 0 53px;
  }
`

const Description = styled.div`
  ${body16MainStyles}
  margin-bottom: 30px;

  @media ${breakpoints.tablet} {
    width: calc(5 / 12 * 100vw);
    margin-bottom: 0;
    padding-right: calc(0.5 / 12 * 100vw);
  }

  @media ${breakpoints.laptop} {
    ${body16MainStyles}
    margin-bottom: auto;
    line-height: 24px;
  }

  @media ${breakpoints.desktop} {
    ${body16MainStyles}
    margin-bottom: auto;
    line-height: 24px;
  }
`

const StyledButton = styled(LinkButton)`
  width: 217px;
  margin-top: auto;

  @media ${breakpoints.tablet} {
    margin-top: 0;
  }

  @media ${breakpoints.laptop} {
    max-width: calc(2.5 / 12 * 100vw);
    margin-top: 16px;

    ${Button} {
      height: auto;
      padding: 12px;
    }
  }

  @media ${breakpoints.desktop} {
    width: 256px;
    margin-top: 0;

    ${Button} {
      height: auto;
      padding: 12px 24px;
    }
  }
`

const centeredProps = `
  ${ContentContainer} {
    @media ${breakpoints.laptop} {
      padding: 0 0 0 calc(0.5 / 12 * 100vw);
      justify-content: center;
    }
  }

  ${Title} {
    @media ${breakpoints.laptop} {
      margin: 0 0 53px;
    }
  }

  ${Description} {
    @media ${breakpoints.laptop} {
      margin-bottom: 0;
    }
  }

  ${StyledButton} {
    @media ${breakpoints.laptop} {
      margin-top: 72px;
    }
  }
`

const reversedProps = `
  @media ${breakpoints.laptop} {
    flex-direction: row-reverse;
  }

  ${ContentContainer} {
    @media ${breakpoints.laptop} {
      padding-left: 0;
      padding-right: calc(0.5 / 12 * 100vw);
    }
  }
`

const Container = styled.div<any>`
  display: flex;
  flex-direction: column;

  @media ${breakpoints.laptop} {
    padding: 0 calc(1 / 12 * 100vw);
    flex-direction: row;
  }

  ${({ centered }) => (centered ? centeredProps : "")}
  ${({ reversed }) => (reversed ? reversedProps : "")}
`

export interface ExploreLargeCardProps {
  image: FluidObject
  showTags?: boolean
  highlightLabel?: string
  highlightText?: string
  title: string
  description: string
  actionText: string
  actionLink: string
  centered?: boolean
  reversed?: boolean
}

const ExploreLargeCard: React.FC<ExploreLargeCardProps> = ({
  image,
  showTags,
  highlightLabel,
  highlightText,
  title,
  description,
  actionText,
  actionLink,
  centered,
  reversed,
}) =>
  image ? (
    <Container reversed={reversed} centered={centered}>
      <GatsbyLink to={actionLink}>
        <GatsbyImage fluid={image} />
        <TabletOnly>
          {showTags && highlightText && (
            <HighlightText>{highlightText}</HighlightText>
          )}
        </TabletOnly>
      </GatsbyLink>
      <ContentContainer>
        <MobileAndDesktopOnly>
          {!showTags && !centered && <HighlightSpacer />}
          {showTags && highlightLabel && (
            <HighlightLabel>{highlightLabel}</HighlightLabel>
          )}
          {showTags && highlightText && (
            <HighlightText>{highlightText}</HighlightText>
          )}
        </MobileAndDesktopOnly>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <StyledButton to={actionLink} text={actionText} />
      </ContentContainer>
    </Container>
  ) : null

export default ExploreLargeCard
