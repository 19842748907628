import React from "react"
import styled from "styled-components"
import colors from "@constants/colors"
import animation, { cubicBezier } from "@constants/animation"
import breakpoints from "@constants/breakpoints"
import { UAGLogo, Instagram, Twitter, Facebook, Youtube } from "../icons"
import {
  FooterProps,
  Footer,
  LogoWrapper,
  Logo,
  Main,
  Social,
  SocialIconWrapper,
  SocialIcon,
  LegalLink,
  Copyright,
} from "./common"
import { DesktopOnly } from "@utils/MediaQuery"

const Dash = styled.div`
  width: 26px;
  height: 1px;
  background-color: ${colors.offBlack};
  transform-origin: right;
  transition: background-color ${animation.quick} ${cubicBezier} 150ms,
    transform 350ms cubic-bezier(0.24, 0, 0.18, 1.5) 100ms;
`

const TopLine = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 7px;
  height: 1px;
  background-color: ${colors.offBlack};
  transform-origin: right;
  transform: translateX(0) rotate(45deg) scaleX(0);
  transition: background-color ${animation.quick} ${cubicBezier} 150ms,
    transform 200ms cubic-bezier(0.5, 0.15, 0, 1);
`

const BottomLine = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 7px;
  height: 1px;
  background-color: ${colors.offBlack};
  transform-origin: right;
  transform: translateX(0) rotate(-45deg) scaleX(0);
  transition: background-color ${animation.quick} ${cubicBezier} 150ms,
    transform 200ms cubic-bezier(0.5, 0.15, 0, 1);
`

const hoverProps = `
  ${Dash} {
    background-color: ${colors.offWhite};
    transform: scaleX(0.6);
    transition: background-color ${animation.quick} ${cubicBezier}, transform 100ms cubic-bezier(0.5, 0.15, 0, 1);
  }

  ${TopLine} {
    background-color: ${colors.offWhite};
    transform: translateX(1px) rotate(45deg) scaleX(1);
    transition: background-color ${animation.quick} ${cubicBezier}, transform 400ms cubic-bezier(0, 1.4, 0.5, 1) 60ms;
  }

  ${BottomLine} {
    background-color: ${colors.offWhite};
    transform: translateX(1px) rotate(-45deg) scaleX(1);
    transition: background-color ${animation.quick} ${cubicBezier}, transform 400ms cubic-bezier(0, 1.4, 0.5, 1) 90ms;
  }
`

export const animationStyles = `
  @media ${breakpoints.laptop} {
    &:hover {
      cursor: pointer;
      ${hoverProps}
    }
  }
`

const Column = styled.div`
  display: flex;
  width: calc(6 / 12 * 100vw);
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
`

const Sub = styled.div`
  display: flex;
  margin-bottom: 59px;
  margin-top: 85px;

  > div:first-child {
    flex-shrink: 0;
  }
`

const LegalLinkWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  > * {
    margin-right: 1.25rem;
  }

  > *:last-child {
    margin-right: 0;
  }
`

const DesktopFooter: React.FC<FooterProps> = ({
  socialLinks,
  legalLinks,
  copyrightText,
}) => {
  const renderLegalLink = (legalUrl, legalText) =>
    legalUrl && <LegalLink to={legalUrl}>{legalText}</LegalLink>

  const renderSocialLink = (socialUrl, IconComponent) =>
    socialUrl && (
      <SocialIconWrapper href={socialUrl} target="_blank" rel="noopener">
        <SocialIcon>{IconComponent}</SocialIcon>
      </SocialIconWrapper>
    )

  const { instagram, twitter, facebook, youtube } = socialLinks || {}

  const { privacyPolicy, limitationsOfLiability, termsAndConditions } =
    legalLinks || {}

  return (
    <DesktopOnly>
      <Footer>
        <Main>
          <Column>
            <LogoWrapper>
              <Logo to="/">
                <UAGLogo />
              </Logo>
            </LogoWrapper>
          </Column>
          <Social>
            {renderSocialLink(instagram, <Instagram />)}
            {renderSocialLink(twitter, <Twitter />)}
            {renderSocialLink(facebook, <Facebook />)}
            {renderSocialLink(youtube, <Youtube />)}
          </Social>
        </Main>
        <Sub>
          {copyrightText && <Copyright>{copyrightText}</Copyright>}
          <LegalLinkWrapper>
            {renderLegalLink(privacyPolicy, "Privacy Policy")}
            {renderLegalLink(termsAndConditions, "Terms and Conditions")}
            {renderLegalLink(
              limitationsOfLiability,
              "Limitations of Liability"
            )}
          </LegalLinkWrapper>
        </Sub>
      </Footer>
    </DesktopOnly>
  )
}

export default DesktopFooter
