import React from "react"
import styled from "styled-components"
import colors from "../../constants/colors"
import breakpoints from "../../constants/breakpoints"
import {
  body16MainStyles,
  h4Styles,
  h5Styles,
  smallcapsBold13Styles,
} from "../../constants/typography"
import TextButton from "../buttons/TextButton"
import TextField from "../TextInput"
import { TooltipPopup } from "@components/Tooltip"

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 334px;
  padding: 0 20px;
  @media ${breakpoints.laptop} {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 calc(2 / 12 * 100vw) 0 calc(1 / 12 * 100vw);
  }
`

const Heading = styled.h4`
  ${smallcapsBold13Styles}
  margin: 0;
  text-align: center;
  margin-bottom: 42px;
  width: 260px;
  @media ${breakpoints.laptop} {
    ${h5Styles}
    flex-shrink: 0;
    text-align: left;
    width: calc(3 / 12 * 100vw);
    margin-right: calc(1 / 12 * 100vw);
  }
  @media ${breakpoints.desktop} {
    ${h4Styles}
    margin-right: calc(1 / 12 * 100vw);
  }
`

const FormSubmit = styled(TextButton)`
  margin-top: 24px;
  padding-top: 4px;
  flex-shrink: 0;
  @media ${breakpoints.laptop} {
    width: calc(1.5 / 12 * 100vw);
    margin-left: calc(1 / 24 * 100vw);
    margin-top: 0;
  }
`

const Field = styled(TextField)`
  @media ${breakpoints.laptop} {
    transform: translateY(7px);
  }
`

const postSubscribeNewsletter = (body) => {
  return fetch("/.netlify/functions/postSubscribeNewsletter", {
    method: "POST",
    body: body,
    headers: { "Content-Type": "application/json" },
  })
}

export const Newsletter: React.FC = () => {
  const [email, setEmail] = React.useState("")
  const [touchedSubmit, setTouchedSubmit] = React.useState(false)
  const [successful, setSuccessful] = React.useState(false)
  const [error, setError] = React.useState(false)

  const onSubmitEmail = async () => {
    setTouchedSubmit(true)
    postSubscribeNewsletter(
      JSON.stringify({
        api_key: process.env.GATSBY_KLAVIYO_API_KEY,
        profiles: [
          {
            email,
          },
        ],
      })
    )
      .then(() => {
        setSuccessful(true)
        setError(false)
        setTouchedSubmit(false)
        setEmail("")
      })
      .catch((err) => {
        setError(true)
      })
  }

  React.useEffect(() => {
    let timeout
    if (successful) {
      timeout = setTimeout(() => {
        setSuccessful(false)
      }, 3000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [successful])

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmitEmail()
      }}
    >
      <Heading>Keep in touch with our monthly newsletter</Heading>
      <Field
        type="email"
        name="email"
        placeholder={"Email"}
        onChange={(e) => setEmail(e.target.value)}
        // onBlur={() => {}}
        value={email}
        error={touchedSubmit && email.length > 0 && !emailRegex.test(email)}
      />
      <SubmitButtonAndTooltipWrapper>
        <FormSubmit text={successful ? "Subscribed" : "Get it"} />
        {successful && (
          <TooltipPopup>
            <TooltipText>You're subscribed! 🤘</TooltipText>
          </TooltipPopup>
        )}
      </SubmitButtonAndTooltipWrapper>
    </Form>
  )
}

const SubmitButtonAndTooltipWrapper = styled.div`
  position: relative;
  width: 100%;
`

const TooltipText = styled.p`
  ${body16MainStyles}
  color: ${colors.offWhite};
  padding: 0 20px;
`

const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
