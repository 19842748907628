import React from "react"
import styled from "styled-components"
import Link from "gatsby-link"
import colors from "@constants/colors"
import animation, {
  cubicBezier,
  cubicBezierBackgroundColor,
} from "@constants/animation"
import breakpoints from "@constants/breakpoints"
import {
  h3Styles,
  h4Styles,
  smallcapsBold13Styles,
  smallcapsBold14Styles,
} from "@constants/typography"
import { Arrow } from "@components/icons"

const containerStyles = `
  display: flex;
  width: 100%;
  padding: 66px 32px;
  flex-direction: column;
  transition: opacity ${animation.notQuiteLong} ${cubicBezierBackgroundColor};
  background: ${colors.reflowBlack};
  opacity: 1;

  @media ${breakpoints.tablet} {
    padding: calc(1.5 / 12 * 100vw) calc(1 / 12 * 100vw);
    flex-direction: row;
  }

  @media ${breakpoints.laptop} {
    padding: calc(1 / 12 * 100vw);
    flex-direction: row;

    &:hover {
      opacity: 0.96;
    }
  }
`

const ClickLinkContainer = styled.button`
  ${containerStyles}
  cursor: pointer;

  border: none;
`

const LinkContainer = styled(Link)`
  ${containerStyles}
`

const ActionContainer = styled.div`
  ${containerStyles}
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const ColumnA = styled(Column)`
  @media ${breakpoints.tablet} {
    width: calc(3 / 12 * 100vw);
  }

  @media ${breakpoints.laptop} {
    width: calc(2 / 12 * 100vw);
  }
`

const ColumnB = styled(Column)`
  margin-top: 18px;

  @media ${breakpoints.tablet} {
    margin-top: 0;
    flex-grow: 1;
  }

  @media ${breakpoints.laptop} {
    margin-top: 0;
    flex-grow: 1;
  }
`

const Smallcaps = styled.div`
  ${smallcapsBold13Styles}
  color: ${colors.mediumDarkGray};
  letter-spacing: 0.9px;
  text-align: left;

  @media ${breakpoints.tablet} {
    ${smallcapsBold14Styles}
    width: 127px;
    color: ${colors.mediumDarkGray};
    letter-spacing: 2px;
  }

  @media ${breakpoints.laptop} {
    ${smallcapsBold14Styles}
    width: 127px;
    color: ${colors.mediumDarkGray};
    letter-spacing: 2px;
  }
`

const Line = styled.div`
  display: none;
  width: 24px;
  height: 1px;
  margin-top: 61px;
  background: ${colors.mediumDarkGray};

  @media ${breakpoints.tablet} {
    display: initial;
  }

  @media ${breakpoints.laptop} {
    display: initial;
  }
`

const Title = styled.div`
  ${h4Styles}
  color: ${colors.offWhite};
  text-align: left;

  @media ${breakpoints.tablet} {
    ${h3Styles}
    color: ${colors.offWhite};
  }

  @media ${breakpoints.laptop} {
    ${h3Styles}
    color: ${colors.offWhite};
  }
`

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-top: 10px;
  will-change: transform;
  transition: transform ${animation.quick} ${cubicBezier};
  transform: translateX(0);

  @media ${breakpoints.tablet} {
    margin-top: 48px;
  }

  @media ${breakpoints.laptop} {
    margin-top: 48px;

    ${LinkContainer}:hover & {
      transform: translateX(16px);
    }

    ${ClickLinkContainer}:hover & {
      transform: translateX(16px);
    }

    ${ActionContainer}:hover & {
      transform: translateX(16px);
    }
  }
`

export interface ReflowBlockProps {
  title: string
  subtitle: string
  to?: string
  onClick?: () => void
}

const ReflowBlock = ({ title, subtitle, to, onClick }: ReflowBlockProps) => {
  if (onClick) {
    return (
      <ClickLinkContainer onClick={onClick}>
        <ColumnA>
          <Smallcaps>{subtitle}</Smallcaps>
          <Line />
        </ColumnA>
        <ColumnB>
          <Title>{title}</Title>
          <IconWrapper>
            <Arrow strokeColor={colors.offWhite} />
          </IconWrapper>
        </ColumnB>
      </ClickLinkContainer>
    )
  }

  return (
    <LinkContainer to={to || ""}>
      <ColumnA>
        <Smallcaps>{subtitle}</Smallcaps>
        <Line />
      </ColumnA>
      <ColumnB>
        <Title>{title}</Title>
        <IconWrapper>
          <Arrow strokeColor={colors.offWhite} />
        </IconWrapper>
      </ColumnB>
    </LinkContainer>
  )
}

export default ReflowBlock
