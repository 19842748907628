import React from "react"
import styled, { css } from "styled-components"
import colors from "@constants/colors"
import {
  smallcapsBold13Styles,
  body16MainStyles,
  subtextStyles,
} from "@constants/typography"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"

const TextInput = styled(TextField)<any>`
  ${({ error }) => css`
    width: 100%;

    .MuiInput-input {
      ${body16MainStyles}
      color: ${error ? colors.errorBackground : colors.black};
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid ${colors.mediumGray};
    }

    && .MuiInput-underline:hover:before {
      border-bottom: 1px solid ${colors.black};
    }

    .MuiInput-underline:after {
      border-bottom: 1px solid ${colors.black};
    }

    .MuiInput-input::placeholder,
    .MuiInputLabel-root {
      ${body16MainStyles}
      color: ${error ? colors.errorBackground : colors.mediumGray};
      opacity: 1;
    }

    && .MuiInputLabel-shrink {
      ${smallcapsBold13Styles}
      font-size: calc(11px / 0.75);
      line-height: calc(19px / 0.75);
      color: ${error ? colors.errorBackground : colors.mediumGray};

      &.Mui-focused {
        color: ${error ? colors.errorBackground : colors.black};
      }
    }

    .MuiFormHelperText-root {
      ${subtextStyles}
      margin-top: 12px;
      padding-left: 16px;
      color: ${error ? colors.errorBackground : colors.black};
    }
  `}
`

export default (props: TextFieldProps) => <TextInput {...props} />
