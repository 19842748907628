import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Image, { FluidObject, GatsbyImageProps } from "gatsby-image"
import breakpoints from "@constants/breakpoints"
import colors from "@constants/colors"
import { body16MainStyles, smallcapsBold13Styles } from "@constants/typography"
import AnimatedDash, {
  animationStyles,
} from "@components/buttons/common/AnimatedDash"

const Container = styled.div``

const GatsbyLink = styled(Link)<any>`
  ${({ to }) => (!to.length ? "pointer-events: none" : "cursor: pointer")};
  ${animationStyles}
`

const GatsbyImage = styled(Image)<GatsbyImageProps>`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-grow: 0;
  flex-shrink: 0;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 11px 0 19px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: flex-start;

  @media ${breakpoints.laptop} {
    padding: 27px 0 28px;
  }
`

const Tag = styled.div`
  ${smallcapsBold13Styles}
  display: flex;
  height: 24px;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  color: ${colors.offWhite};
  background-color: ${colors.black};
  border-radius: 2px;

  margin-bottom: 27px;
`

const Title = styled.div`
  ${body16MainStyles}
`

export interface ExploreSmallCardProps {
  image: FluidObject
  tag?: string
  title: string
  link?: string
}

const ExploreSmallCard: React.FC<ExploreSmallCardProps> = ({
  image,
  tag,
  title,
  link,
}) =>
  image ? (
    <GatsbyLink to={link || ""}>
      <Container>
        <div
          style={{ position: "relative", width: "100%", paddingTop: "56.25%" }}
        >
          <GatsbyImage fluid={image} style={{ position: "absolute" }} />
        </div>
        <ContentContainer>
          {tag && <Tag>{tag}</Tag>}
          <Title>{title}</Title>
        </ContentContainer>
      </Container>
    </GatsbyLink>
  ) : null

export default ExploreSmallCard
