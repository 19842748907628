import React from "react"
import DesktopFooter from "./DesktopFooter"
import { FooterProps } from "./common"

const Footer: React.FC<FooterProps> = (props) => {
  return (
    <>
      <DesktopFooter {...props} />
    </>
  )
}

export default Footer
