import React from "react"
import styled from "styled-components"
import colors from "@constants/colors"

export const TopArrow = styled.div`
  content: "";
  height: 20px;
  width: 20px;
  background-color: ${colors.offBlack};
  position: absolute;
  top: -10px;
  right: 50%;
  transform: rotate(45deg);
`
//   transform: rotate(45deg) translateX(90px) translateY(-60px);

export const Content = styled.div`
  position: absolute;
  background-color: ${colors.offBlack};
  right: 25%;
  top: calc(100% + 10px);
  margin-top: 10px;
  padding: 16px 0;
  z-index: 20;
`

export const Container = styled.div``

export function TooltipPopup(props) {
  return (
    <Content>
      <TopArrow />
      <Container>{props.children}</Container>
    </Content>
  )
}
