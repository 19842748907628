import React from "react"
import styled from "styled-components"
import colors from "../../../constants/colors"
import animation, {
  cubicBezierBackgroundColor,
} from "../../../constants/animation"
import breakpoints from "../../../constants/breakpoints"
import {
  smallcapsBold13Styles,
  smallcapsRegular13Styles,
} from "../../../constants/typography"

const invertedProps = `
  ${smallcapsRegular13Styles}
  color: ${colors.black};
  background: none;

  @media ${breakpoints.laptop} {
    &:active {
      background: none;
    }
    &:hover {
      background: none;
    }
  }
`
export const Button = styled.button<{ inverted?: boolean }>`
  ${smallcapsBold13Styles}
  display: flex;
  width: 100%;
  height: 48px;
  align-items: center;
  justify-content: center;
  color: ${colors.offWhite};
  transition: background-color ${animation.medium} ${cubicBezierBackgroundColor};
  background-color: ${colors.black};
  border: none;
  border-radius: 2px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: ${colors.black};
  }

  @media ${breakpoints.laptop} {
    &:hover {
      background-color: ${colors.hoverBlack};
    }
  }

  ${({ inverted }) => (inverted ? invertedProps : "")}
`

export interface TextButtonProps
  extends React.PropsWithoutRef<JSX.IntrinsicElements["button"]> {
  text: string
  inverted?: boolean
  className?: string
}

const TextButton: React.FC<TextButtonProps> = ({
  text,
  inverted,
  className,
  onClick,
}) => (
  <Button className={className} onClick={onClick} inverted={inverted}>
    {text}
  </Button>
)

export default TextButton
