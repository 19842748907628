import React from "react"
import * as R from "ramda"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
//
import Footer from "@components/Footer"
import breakpoints from "@constants/breakpoints"
import ReflowBlock from "@components/ReflowBlock"
import { Newsletter } from "@components/Newsletter"
import { h2Styles, h4Styles } from "@constants/typography"
import ExploreLargeCard from "@components/ExploreLargeCard"
import ExploreSmallCard from "@components/ExploreSmallCard"
import * as config from "../config"
import Layout from "@components/Layout"
import Helmet from "@components/Helmet"

const Main = styled.div`
  padding-top: 56px;

  @media ${breakpoints.laptop} {
    padding-top: 88px;
  }
`

const LatestNewsHeadingWrapper = styled.div`
  padding: 0 calc(1 / 12 * 100vw);
  margin: 51px 0 35px;

  @media ${breakpoints.laptop} {
    margin: 145px 0;
  }

  > h1 {
    @media ${breakpoints.laptop} {
      ${h2Styles}
    }

    @media ${breakpoints.mobileAndTabletOnly} {
      ${h4Styles}
    }
  }
`

const ExploreRowWrapper = styled.div`
  display: grid;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: space-between;
  grid-template-columns: 32% 32% 32%;
  padding: 0 calc(1 / 12 * 100vw);
  margin-top: 100px;

  @media ${breakpoints.mobileAndTabletOnly} {
    display: flex;
    flex-direction: column;
    padding: 0 18px;

    > * {
      margin-bottom: 25px;
    }
  }

  @media ${breakpoints.laptop} {
    > *:last-child {
      margin-right: 0;
    }
  }
`
// > * {
//   margin-right: 1.25rem;
// }

export default function (props) {
  const data = useStaticQuery(
    graphql`
      query AllContentfulPostQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
        allContentfulGlobalSettings {
          nodes {
            copyrightText
            socialMediaLinks {
              facebook
              instagram
              twitter
              youtube
            }
            legalLinks {
              privacyPolicy
              termsAndConditions
              limitationsOfLiability
            }
          }
        }
        allContentfulPost(sort: { fields: createdAt, order: DESC }) {
          edges {
            node {
              id
              slug
              title
              createdAt
              shortDescription {
                shortDescription
              }
              heroImage {
                ...ImageAsset
              }
              gallery {
                id
                archiveZip {
                  id
                  title
                  description
                  file {
                    url
                    fileName
                    contentType
                  }
                }
                assets {
                  ... on ContentfulGalleryImage {
                    id
                    asset {
                      id
                      title
                      description
                      contentful_id
                      file {
                        url
                        fileName
                        contentType
                      }
                      fluid {
                        aspectRatio
                        src
                        srcSet
                        srcSetWebp
                        srcWebp
                        sizes
                      }
                    }
                    __typename
                  }
                  ... on ContentfulGalleryPdf {
                    id
                    pdf {
                      id
                      title
                      description
                      contentful_id
                      file {
                        url
                        fileName
                        contentType
                      }
                      fluid {
                        srcWebp
                        srcSetWebp
                        srcSet
                        sizes
                        src
                        aspectRatio
                      }
                    }
                    displayImage {
                      id
                      title
                      description
                      contentful_id
                      fluid {
                        aspectRatio
                        sizes
                        src
                        srcSet
                        srcSetWebp
                        srcWebp
                      }
                    }
                    __typename
                  }
                  ... on ContentfulGalleryVideo {
                    id
                    title
                    description
                    contentful_id
                    playVideoLink
                    lowResDownloadLink
                    highResDownloadLink
                    thumbnail {
                      id
                      title
                      description
                      fluid {
                        src
                        sizes
                        aspectRatio
                        srcSet
                        srcSetWebp
                        srcWebp
                      }
                      contentful_id
                    }
                    __typename
                  }
                }
              }
            }
          }
        }
      }

      fragment ImageAsset on ContentfulAsset {
        id
        title
        description
        contentful_id
        file {
          url
          fileName
          contentType
        }
        fluid(quality: 80) {
          sizes
          src
          srcSet
          srcWebp
          srcSetWebp
          aspectRatio
        }
        __typename
      }
    `
  )

  //  [ [head, sub, sub, sub], [] ]
  const chunkedPosts = R.splitEvery(7, data.allContentfulPost.edges)

  return (
    <Layout>
      <Helmet title="Urban Armor Gear News" />
      <Main>
        <LatestNewsHeadingWrapper>
          <h1>Latest News</h1>
        </LatestNewsHeadingWrapper>

        {chunkedPosts.map((chunk: any, index) => (
          <React.Fragment key={chunk[0].node.id || index}>
            <ExploreLargeCard
              showTags
              key={chunk[0].node.id}
              title={chunk[0].node.title}
              description={chunk[0].node.shortDescription.shortDescription}
              highlightText="product"
              actionText="read it now"
              actionLink={
                data.site.siteMetadata.siteUrl + "/" + chunk[0].node.slug
              }
              image={chunk[0].node.heroImage.fluid}
            />
            <ExploreRowWrapper>
              {chunk.slice(1, 7).map(({ node: post }) => (
                <ExploreSmallCard
                  key={post.id}
                  title={post.title}
                  tag="read it now"
                  link={
                    data.site.siteMetadata.siteUrl + "/" + chunk[0].node.slug
                  }
                  image={post.heroImage.fluid}
                />
              ))}
            </ExploreRowWrapper>
          </React.Fragment>
        ))}

        <Newsletter />
      </Main>
      <ReflowBlock
        title="SHOP"
        subtitle="SEEN ENOUGH?"
        to={config.SHOP_UAG_URL}
      />
      <Footer
        legalLinks={data.allContentfulGlobalSettings.nodes[0].legalLinks}
        socialLinks={data.allContentfulGlobalSettings.nodes[0].socialMediaLinks}
        copyrightText={data.allContentfulGlobalSettings.nodes[0].copyrightText}
      />
    </Layout>
  )
}
